.form-component.sidebar-panel {
    > .card {
        border-width: 0;
    }
    .form-group {
        margin-bottom: 0;
        > .control-label {
            font-size: .9rem;
        }

        > fieldset > legend[id^="root_"][id$="__title"] {
            font-size: .9rem;
            background-color: transparent;
            color: inherit;
        }

        &.field.field-object {
            > .row, > fieldset > .row {
                margin-left: 0;
                margin-right: 0;
            }

            &.fieldset > fieldset {
                border: 1px dotted gray;
                padding: 0px 12px;
                > legend {
                    text-align: center;
                    background-color: transparent;
                    width: auto;
                }
            }
        }

        + .form-group.separator::before {
            display: block;
            content: " ";
            margin-top: 12px;
            margin-bottom: 12px;
            border-top: 1px solid black;
        }
    }
}