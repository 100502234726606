.log-entry {
    margin-top: 2px;
}

.log-entry:first-child {
    margin-top: 32px;
}

.log-entry .card-header {
    position: relative;
}

.log-entry .card-header>.action {
    position: absolute;
    right: 15px;
    font-size: .7em;
}

.log-entry .card-header>.index {
    display: inline-block;
    min-width: 2em;
    text-align: center;
    padding-right: 1em;
}

.log-entry .card-header>.status {
    display: inline-block;
}

.log-entry .card-header>.status {
    display: inline-block;
}


.log-entry .state {
    font-family: monospace;
    font-size: .8em;
    background-color: rgba(128, 128, 128, .1);
    white-space: pre;
}

.log-entry .timestamp {
    font-size: 0.7em;
    position: absolute;
    right: 15px;
    bottom: 0;
}


.requisition-history-list {
    margin: -20px;
    > .entry {
        padding: 5px 20px;

        &:not(:last-child) {
            border-bottom: 1px solid $striped-list-border-color;
        }

        &:nth-of-type(odd) {
            background-color: $striped-list-background-color;
        }

        &:last-child {
            border-bottom-left-radius: 15px;
        }

        .timestamp {
            font-size: .7rem;
            font-weight: bold;
        }
        .username {
            font-size: .7rem;
            color: $dark-gray;
        }
        .action {
            font-size: .85rem;
            color: $black;
        }
        .option {
            font-size: .75rem;
            color: $black;
        }
        .reason {
            border: 1px solid $dark-gray;
            font-size: .85rem;
            color: $black;
            padding: 3px 5px;
        }
    }
}

.requisition-history-expanded {
    .requisition-history {
        .date-time {
            font-size: small;
            font-weight: bold;
        }

        .text {
            white-space: pre-wrap;
        }
    }
}