.requisition-note-sidepanel-component {
    .requisition-note-add {
        textarea {
            width: 100%;
            height: 100px;
            border-radius: 5px;
        }
    }

    .requisition-notes-list {
        &::before {
            content: " ";
            border-top: 1px solid $separator-color;
            display: block;
            margin: .5rem -20px;
            height: 0;
        }
    }

    .requisition-notes-list .requisition-note-container {
        &:not(:last-child)::after {
            content: " ";
            border-top: 1px solid $separator-color;
            display: block;
            margin: .5rem -20px;
            height: 0;
        }

        .requisition-note-header {
            font-size: small;
        }

        .requisition-note-date-time {
            font-size: small;
            font-weight: bold;

            &::after {
                content: " - ";
            }
        }

        .requisition-note-user {
            font-size: small;
            font-weight: bold;

            &::after {
                content: " - ";
            }
        }

        .requisition-note-action {
            float: right;
            font-size: small;
            font-weight: bold;
        }

        .requisition-note-text {
            white-space: pre-wrap;
        }

    }

    .not-notes-list {
        margin-top: 20px;
    }

    .notes-list {

        margin-top: 20px;
        padding: 20px;
        border: 1px solid rgb(222, 226, 230);
        border-radius: 7px;

        .requisition-note-container {
            &:not(:last-child)::after {
                content: " ";
                border-top: 1px solid rgb(222, 226, 230);
                display: block;
                margin: .5rem -20px;
                height: 0;
            }

            .requisition-note-header {
                font-size: small;
            }

            .requisition-note-date-time {
                font-size: small;
                font-weight: bold;

                &::after {
                    content: " - ";
                }
            }

            .requisition-note-user {
                font-size: small;
                font-weight: bold;

                &::after {
                    content: " - ";
                }
            }

            .requisition-note-action {
                float: right;
                font-size: small;
                font-weight: bold;
            }

            .requisition-note-text {
                white-space: pre-wrap;
            }
        }

    }
}


.requisition-notes-expanded {

    .requisition-form-notes {
        padding-bottom: 20px;
    }

    .requisition-note-date-time {
        font-size: small;
        font-weight: bold;
    }

    .requisition-note-user {
        font-size: small;
        font-weight: bold;
    }

    .requisition-note-action {
        float: right;
        font-size: small;
        font-weight: bold;
    }

    .requisition-note-text {
        white-space: pre-wrap;
    }

}