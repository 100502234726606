.requisition-form.hidden {
    display: none;
}

form.rjsf {
    margin-top: -1.25rem;

    .is-highligthed {
        outline: 5px solid blue;
        cursor: crosshair !important;
    }

    .form-group {
        .control-label {
            margin-bottom: .5rem;
        }

        .checkbox-inline>span {
            margin-right: $inline-input-gap;

            >input[type=checkbox] {
                margin-right: $inline-label-gap;
            }
        }

        .checkbox>label>span {
            margin-right: $inline-input-gap;

            >input[type=checkbox] {
                margin-right: $inline-label-gap;
            }
        }

        .field-radio-group>.radio {
            margin-right: $inline-input-gap;

            >label>span>input[type=radio] {
                margin-right: $inline-label-gap;
            }
        }

        .radio-inline {
            margin-right: $inline-input-gap;

            >span {
                >input[type=radio] {
                    margin-right: $inline-label-gap;
                }
            }
        }

        input,
        select {
            max-width: 20rem;
        }

        .autoCompleteMD {
            max-width: 20rem;
        }


        .btn.btn-block {
            margin-left: 3rem;
            margin-right: 3rem;
            width: 90%;
        }
    }

    .title-2-lines>label {
        height: 3em;
    }

    .array-field-vertical .array-item-list {
        flex-direction: column;
        max-width: 30rem;

        >.array-item {
            display: flex;

            >.col-xs-9 {
                flex-grow: 1;

                .form-group {
                    margin-bottom: 0;
                }
            }

            >.col-xs-3 {
                display: flex;
                align-items: center;
            }
        }
    }

    .array-cards>.array-item-list>.array-item.for-object-row {
        padding-left: 1em;
        padding-right: 1em;
    }

    tr.for-object-row>th {
        white-space: pre;
    }

    .array-item.for-object-row>td {
        padding: 0;

        >.form-group {
            margin-bottom: 0;

            >.control-label {
                display: none;
            }

            >.form-group {
                margin-bottom: 0;
            }
        }
    }

    legend[id^="root_"][id$="__title"] {
        position: relative;
        background-color: $pd-color-4;
        color: $primary-text;
        border-radius: 15px 15px 0 0;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        margin-left: -1.25rem;
        margin-right: -1.25rem;
        box-sizing: content-box;

        >.expandable {
            position: absolute;
            right: 12px;
            height: 1.5em;
            width: 1.5em;
            background-color: transparent;
            border-width: 0;
            color: $white;

            >* {
                font-size: 2.5rem;
            }
        }
    }

    .btn.btn-add {
        $color: map-get($theme-colors, "secondary");
        @include button-variant($color, $color);
    }

    .radio-column {
        >.field-radio-group {
            flex-direction: column;
        }
    }

    legend#root__title:empty {
        margin: 0;
    }
}

.field-boolean {
    position: relative;

    >.checkbox {
        display: inline-block;
    }

    >.help-block {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }
}

.hidden {
    display: none;
}


.form-group.field.field-object.flex-row {

    >fieldset,
    >.form-group>.prop-fields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        >* {
            margin-left: 12px;
        }
    }
}


.no-title>fieldset>legend {
    display: none;
}


.row-index {
    width: 4em;

    >.form-group>input {
        text-align: center;
    }
}

.form-container {
    display: flex;
    flex-direction: row;
    margin-left: -24px;
    margin-right: -24px;

    >.sidepanel {
        $tabwidth: 120px;
        $tabheight: 24px;
        $tabgap: 10px;
        $tab-v-offset: 36px+2 * 8px;
        $tab-border-radius: 15px;
        $collapsed-width : $tabheight + $tabgap;
        $expanded-width : 260px;

        &.expanded {
            flex: 0 0 $expanded-width;
        }

        flex: 0 0 $collapsed-width;
        width: $collapsed-width;

        >.expandable-tab-side-panel-tab {
            width: $tabwidth;
            height: $tabheight;
            border-radius: $tab-border-radius $tab-border-radius 0px 0px;
            font-size: 1rem;
            margin-bottom: $tabwidth;
            border-width: 0;
            color: $white;
            background-color: $pd-color-4;
        }

        >.expandable-tab-side-panel-panel {
            margin-left: $tabgap / 2;
            margin-right: $tabgap / 2;
            border-radius: 15px;

            >.card-header {
                position: relative;
                background-color: $pd-color-4;
                color: $white;
                font-size: 1.65rem;
                padding-top: 0;
                font-weight: bold;
                padding-bottom: 0;

                >.btn-bar {
                    position: absolute;
                    right: 0;
                    top: -8px;
                    padding: 0;

                    >.btn {
                        position: relative;
                        right: auto;
                        top: auto;
                        border-radius: 50%;
                        background-color: $pd-color-4;
                        color: $white;
                        width: 24px;
                        min-width: auto;
                        max-width: auto;
                        padding: 0;
                        height: 24px;
                        margin-left: 12px;

                        &:hover {
                            color: $pd-color-4;
                            background-color: $white;
                        }
                    }
                }
            }

            >.card-body {
                overflow: auto;
                max-height: 40vh;
                border-radius: 0;
            }

            &::after {
                content: " ";
                height: 15px;
            }
        }

        >.expandable-tab-side-panel-tab+.expandable-tab-side-panel-panel {
            margin-top: $tab-v-offset;
        }

        >.expandable-tab-side-panel-panel+.expandable-tab-side-panel-panel {
            margin-top: 2 * $tabgap;
        }

        &.sidepanel-left {
            >.expandable-tab-side-panel-tab {
                // rotate(-90deg) translateY(9px) translateX(-173px)
                transform: rotate(-90deg) translate(-($tabwidth + $tab-v-offset), $tabgap);
                transform-origin: top left;
            }

            &.expanded>.expandable-tab-side-panel-tab {
                transform: rotate(-90deg) translate(-($tabwidth + $tab-v-offset), $expanded-width - $tabheight);
            }

            order: 1;
        }

        &.sidepanel-right {
            >.expandable-tab-side-panel-tab {
                transform: rotate(90deg) translate($tabwidth + $tab-v-offset, $tabwidth - $tabheight);
                transform-origin: top right;
            }

            &.expanded>.expandable-tab-side-panel-tab {
                transform: rotate(90deg) translate($tabwidth + $tab-v-offset - $tabheight, $tabwidth);
                transform-origin: bottom right;
            }

            order: 3;
        }

    }


    >.form-component {
        order: 2;
        flex-grow: 1;
    }

    >.toolbar-bottom-affix {
        position: fixed;
        bottom: 0;
        margin-left: 5px;
    }

}


.form-group.field.field-inline {
    display: flex;
    flex-direction: row;

    >label {
        margin-bottom: 0;
        padding-right: 12px;

        &:after {
            content: ": "
        }
    }

    >.form-group {
        margin-bottom: 0;
        flex-grow: 1;

        >input {
            width: 100%;
            max-width: 1200px;
        }
    }
}

.w-sm-s {
    .control-label {
        font-size: 14px !important;
    }
}


.for-object-row {
    >th {
        text-align: center;
        vertical-align: middle;
    }

    .w-l {
        width: 16rem;
    }

    .w-xl {
        width: 25rem;
    }

    .w-m {
        width: 10rem;
    }

    .w-sm {
        width: 4.5rem;
    }

    .w-sm-s {
        width: 4.5rem;
        font-size: 13px;
        padding: 2px;
    }

    ::-webkit-input-placeholder {
        color: #b4b4b4;
        font-size: 14px;
    }

    .w-sm-s :-ms-input-placeholder {
        color: #b4b4b4;
        font-size: 14px;
    }

    .w-sm-s ::placeholder {
        color: #b4b4b4;
        font-size: 14px;
    }
}

.no-gridColumnGap {
    grid-column-gap: 0 !important;

    input {
        max-width: 100% !important;
    }
}


.no-mb {
    margin-bottom: 0 !important;
}

.no-border {
    border: 0px !important;
}

.no-bt {
    border-top: 0px !important;
}

.no-pb {
    padding-bottom: 0px !important;
}


.ft-12 {
    font-size: 12pt !important;
}

.input-block>.form-group>input {
    max-width: 100% !important;
}

.node-title {
    dx: -200;
    fill: green;
}

.node-label {}

.legendBottom-button {
    font-size: 10px;
    color: gray;
    display: block;
}

.endorsement-equipment-legend {
    label {
        font-size: 13px;
        color: #727272;
    }
}


.has-buttons-position-top {
    position: relative;

    .buttons-position-top {
        top: 3px;
        position: absolute;
        right: 40px;
    }
}

.has-buttons-position-top-outside {
    position: relative;
    display: flex;
    margin-left: 5em;
    justify-content: space-between;

    .buttons-position-top {
        flex-grow: 0;
        flex-shrink: 0;
    }

}

.fs-12 {
    font-size: 12px;
}

.separator-border {
    border-bottom: 2px dotted #ccc;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom-style: dashed;
}

.ql-editor {
    min-height: 200px !important;
}

.quill-editor iframe {
    pointer-events: none;
}

.modal-header-notification {
    .modal-title {
        width: 100%;
    }

    .notifications-title {
        font-weight: bold;
        font-size: 19px;
    }
}

// .ql-viewer {
//     padding: 12px 15px;
//     border: 1px solid #c1c1c1;
// }

.ql-viewer .ql-font-serif {
    font-family: Georgia, Times New Roman, serif;
}

.ql-viewer .ql-font-monospace {
    font-family: Monaco, Courier New, monospace;
}

.ql-viewer .ql-size-small {
    font-size: 0.75em;
}

.ql-viewer .ql-size-large {
    font-size: 1.5em;
}

.ql-viewer .ql-size-huge {
    font-size: 2.5em;
}

.ql-viewer .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
}

.ql-viewer .ql-align-center {
    text-align: center;
}

.ql-viewer .ql-align-justify {
    text-align: justify;
}

.ql-viewer .ql-align-right {
    text-align: right;
}

.ql-viewer blockquote {
    border-left: 4px solid #ccc;
    padding-left: 16px;
}

.ql-viewer code,
.ql-viewer pre {
    background-color: #f0f0f0;
    border-radius: 3px;
    padding: 6px 10px;
}

.ql-viewer ol>li,
.ql-viewer ul>li {
    list-style-type: none;
}

.ql-viewer ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-num;
}

.ql-viewer ol li:before {
    content: counter(list-num, decimal) '. ';
}

/* indent 1 */
.ql-viewer ol ol li {
    counter-increment: list-1;
}

.ql-viewer ol ol li:before {
    content: counter(list-1, lower-alpha) '. ' !important;
}

.ql-viewer ol ol li {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

/* indent 2 */
.ql-viewer ol ol ol li {
    counter-increment: list-2;
}

.ql-viewer ol ol ol li:before {
    content: counter(list-2, lower-roman) '. ' !important;
}

.ql-viewer ol ol ol li {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

/* indent 3 */
.ql-viewer ol ol ol ol li {
    counter-increment: list-3;
}

.ql-viewer ol ol ol ol li:before {
    content: counter(list-3, decimal) '. ' !important;
}

.ql-viewer ol ol ol ol li {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

/* indent 4 */
.ql-viewer ol ol ol ol ol li {
    counter-increment: list-4;
}

.ql-viewer ol ol ol ol ol li:before {
    content: counter(list-4, lower-alpha) '. ' !important;
}

.ql-viewer ol ol ol ol ol li {
    counter-reset: list-5 list-6 list-7 list-8 list-9;
}

/* indent 5 */
.ql-viewer ol ol ol ol ol ol li {
    counter-increment: list-5;
}

.ql-viewer ol ol ol ol ol ol li:before {
    content: counter(list-5, lower-roman) '. ' !important;
}

.ql-viewer ol ol ol ol ol ol li {
    counter-reset: list-6 list-7 list-8 list-9;
}

/* indent 6 */
.ql-viewer ol ol ol ol ol ol ol li {
    counter-increment: list-6;
}

.ql-viewer ol ol ol ol ol ol ol li:before {
    content: counter(list-6, decimal) '. ' !important;
}

.ql-viewer ol ol ol ol ol ol ol li {
    counter-reset: list-7 list-8 list-9;
}

/* indent 7 */
.ql-viewer ol ol ol ol ol ol ol ol li {
    counter-increment: list-7;
}

.ql-viewer ol ol ol ol ol ol ol ol li:before {
    content: counter(list-7, lower-alpha) '. ' !important;
}

.ql-viewer ol ol ol ol ol ol ol ol li {
    counter-reset: list-8 list-9;
}

/* indent 8 */
.ql-viewer ol ol ol ol ol ol ol ol ol li {
    counter-increment: list-8;
}

.ql-viewer ol ol ol ol ol ol ol ol ol li:before {
    content: counter(list-8, lower-roman) '. ' !important;
}

.ql-viewer ol ol ol ol ol ol ol ol ol li {
    counter-reset: list-9;
}

/* indent 9 */
.ql-viewer ol ol ol ol ol ol ol ol ol ol li {
    counter-increment: list-9;
}

.ql-viewer ol ol ol ol ol ol ol ol ol ol li:before {
    content: counter(list-9, decimal) '. ' !important;
}

/* ql indent */
.ql-viewer .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em;
}

.ql-viewer .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em;
}

.ql-viewer .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em;
}

.ql-viewer .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em;
}

.ql-viewer .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em;
}

.ql-viewer .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em;
}

.ql-viewer .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em;
}

.ql-viewer .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em;
}

.ql-viewer .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em;
}

.ql-viewer .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em;
}

.ql-viewer .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em;
}

.ql-viewer .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em;
}

.ql-viewer .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em;
}

.ql-viewer .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em;
}

.ql-viewer .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em;
}

.ql-viewer .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em;
}

.ql-viewer .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em;
}

.ql-viewer .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em;
}

/* video */
.ql-viewer .ql-video {
    display: block;
    max-width: 100%;
}

.ql-viewer .ql-video.ql-align-center {
    margin: 0 auto;
}

.ql-viewer .ql-video.ql-align-right {
    margin: 0 0 0 auto;
}

.dont-show-component {
    display: none;
}

span.file-link {
    cursor: pointer;
    font-size: 12px;
    display: block;
    margin-left: 10px;
    margin-top: 5px;
}

ul.notification-tabs {
    a.nav-link {
        font-size: 12px;
        color: black !important;
    }
}

div.checkbox-side {
    width: 44%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    margin-right: 6%;
}

.checkbox-side .checkbox-toggle-button-field label.control-label.checkbox {
    font-size: 13px !important;
    text-align: left;
}