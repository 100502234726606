.dashboard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.workTrayIcon {

    padding-bottom: 20px;

    img {
        cursor: pointer;
        max-width: 143px;
    }

    .label {

        p {
            font-size: 19px !important;
            margin: 0px;
            font-weight: bold;
        }

    }
}

.td-actionButtons{
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.worktray {
    >.card {
        >.table {
            margin-bottom: 0;

            >*>tr {

                >td,
                >th {
                    padding: .32rem 0rem .32rem 1.25rem;

                    >.btn {
                        white-space: nowrap;
                        text-overflow: clip;
                    }
                }
            }
        }
    }
}

.show-when-mobile {
    display: none;
}