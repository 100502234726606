.array-item.for-object-row.denied,
.array-item.panel-row.denied {
    background-color: $pd-color-6;
}

.array-item.for-object-row.superseded,
.array-item.panel-row.superseded {
    background-color: $gray;
}


.requisition-form.form-component {
    $radius: 15px;
    $padding: 18px;
    $paddingv: 8px;

    background-color: $white;
    border: $card-border-width solid $card-border-color;
    border-radius: $radius;


    >.requisition-title {
        position: relative;
        background-color: $pd-color-5;
        color: $white;
        font-size: 1.5rem;
        font-weight: bold;
        border-top-left-radius: $radius;
        border-top-right-radius: $radius;
        padding: $paddingv $padding;
        margin-bottom: 8px;

        span.workflow {
            font-size: 1rem;
            padding-top: 7px;
        }

        >.btn-bar {
            position: absolute;
            right: $padding;
            top: 7px;

            >.btn {
                margin-left: 5px;
            }
        }

        >.cancel-btn {
            position: absolute;
            right: $padding;
        }
    }

    >.form-component {
        margin-left: $padding;
        margin-right: $padding;

        >.card {
            border: none;

            legend[id^="root_"][id$="__title"] {
                border-radius: $radius;
            }
        }

        .articles-accounts {
            margin-left: 2%;
            width: auto;

            input {
                padding-left: 4px !important;
            }

            .table th {
                border-bottom: 0 !important;
                border-top: 0 !important;
            }

            .form-group {
                text-align: center;
                margin-bottom: 0 !important;
            }

            .autoComplete {
                text-align: left !important;
            }
        }
    }
}

.requisition-form-full-expanded-sidepanel {
    border-bottom: 0px;
    border-left: 0px;
    border-right: 0px;
    padding: 7px;

    ul.striped-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    ul.striped-list>li {
        border-bottom: 1px solid $striped-list-border-color;
        padding: 6px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    ul.striped-list>li:nth-of-type(odd) {
        background-color: $striped-list-background-color;
    }

    ul.striped-list>li:last-child {
        border-bottom: none;
    }

    .card-header {
        position: relative;
        background-color: #080a2c;
        color: #ffffff;
        font-size: 1.45rem;
        padding-top: 4px;
        font-weight: bold;
        padding-left: 9px;
        padding-bottom: 0;
    }

    .btn-bar {
        position: absolute;
        right: 16px;
        top: 6px;
        padding: 0;

        >.btn {
            position: relative;
            right: auto;
            top: auto;
            border-radius: 8px;
            background-color: #080a2c;
            color: #ffffff;
            width: 24px;
            min-width: auto;
            max-width: auto;
            padding: 0;
            height: 24px;
            margin-left: 12px;

            &:hover {
                color: #080a2c;
                background-color: #ffffff;
            }
        }
    }
}

table {
    span.subBackReason {
        font-size: 11px;
        font-weight: bold;
        display: table-cell;
    }
}

.h-margin {
    margin-left: 10px;
    margin-right: 10px;
}