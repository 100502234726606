.card {
    border-radius: 15px;

    &.square {
        border-radius: 0;
    }

    &.square-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    &.square-bottom {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    > .card-header{
        border-radius: 15px 15px 0 0;

        .title {
            font-size: x-large;
            font-weight: bold;

            &.has-buttons {
                display: inline-block;
                vertical-align: bottom;
                padding-right: 2rem;
            }
        }

        .pagination {
            margin-bottom: 0;
        }
    }

    > .card-footer {
        border-radius: 0 0 15px 15px;
        .pagination {
            margin-bottom: 0;
        }
    }

    > table {
        > thead th { font-weight: normal; }
    }
    
    &.primary {
        background-color: $gray;
        color: $black;
        > table {
            background-color: $primary-card-bg2;
            > thead * { color: $primary; }
            > tbody {
                > tr {
                    margin-top: 5px;
                    background-color: $primary-card-bg2;
                }
            }
        }
        > .card-header {
            color: $white;
            background-color: $pd-color-4;
        }
    }
}

.card.inbox-card {
    margin-bottom: $main-container-gap;
}


.no-bottom-gap {
    margin-bottom: 0 !important;
}
