$navbar-color: $black;
$navbar-bg-color: $white;
$navbar-badge-color: $white;


nav.navbar {
    background-color: $navbar-bg-color;
    color: $navbar-color;
    height: 75px;

    .nav-icon {
        font-size: 2rem;
        color: $navbar-color;
        .icon-badge {
            color: $navbar-badge-color;
        }
    }


    .clock.nav-clock {
        font-size: 3rem;
        margin-right: 1rem;
    }

    .nav.navbar-nav {
        > .navbar-text {
            display: flex;
            align-items: center;
            padding-left: 5px;
            padding-right: 5px;
        }
        > .nav-item {
            display: flex;
            align-items: center;
        }
    }
    a.nav-link {
        color: $gray;
    }

    .navbar-help-text {
        color: $navbar-color
    }

    .navbar-text.text-title {
        color: $pd-color-5;
        font-weight: bold;
    }
}