
.sidebar {
    $bg-color-active: $gray;
    $bg-color-inactive: $white;
    $bg-color-inactive-2: $pd-color-2;
    $icon-color: $pd-color-4;

    position: fixed;
    left: 0;
    top: 75px;
    background-color: $bg-color-inactive;
    bottom: 0;
    // width: $sidebar-width;
    z-index: 1000;

    display: flex;
    flex-direction: row;

    > .nav.nav-tabs {
        flex: 0 0 $sidebar-button-width;
        flex-direction: column;
        padding-top: $main-container-gap;

        > .nav-item {
            > .nav-link {
                border-radius: 0;
                border-width: 0 0 0 5px;
                border-color: $bg-color-inactive;
                padding-left: 7px;
                width: $sidebar-button-width;
                color: $icon-color;
                &.active {
                    background-color: $bg-color-active;
                    border-color: $bg-color-inactive-2;
                    color: $icon-color;
                }
            }
            .fa, .icon {
                font-size: 1.8rem;
            }
        }
    }

    > .tab-content {
        $item-gap: 42px;
        flex: 0 0 $sidebar-content-width;
        background-color: $bg-color-active;
        &.empty {
            flex: 0 0 0;
        }

        > .tab-pane {
            padding-top: $main-container-gap;
        }

        > .tab-pane.home {
            display: flex;
            flex-direction: column;
            align-items: center;
            > :not(:first-child) {
                margin-top: $item-gap;
            }
            .sidebar-status-icon {
                width: 75%;
            }
        }
    }

}


.sidebar-status-icon {
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    :nth-child(1) {
        grid-area: 1 / 1 / 3 / 2;
        align-self: stretch;
        height: 1em;
        text-align: center;
        font-size: 4rem;
    }
    :nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;
        background-color: $gray;
        height: 1.5em;
        text-align: center;
    }
    :nth-child(3) {
        grid-area: 2 / 2 / 3 / 3;
        background-color: $gray;
        height: 1.5em;
        text-align: center;
    }
    :nth-child(4) { grid-area: 3 / 1 / 4 / 3; }
}
