div.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 10000;
}

div.fullscreen>div {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

div.loader-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.loader-container>div {
    margin-left: 1em;
}

.array-item-add {
    margin-left: 1em;
}

.array-item {
    margin-left: 1em;
    padding-bottom: 1em;
}